<template>
  <div>
    <!-- {{ brandsPurchase }} -->
    <div class="options">
      <button class="btn btn-primary" @click="changeOptions(true)">
        Comprar a Laboratórios
      </button>
      <button class="btn btn-info" @click="changeOptions(false)">
        Ver Compras
      </button>
    </div>
    <span v-if="buyBrand == true">
      
      <h1>Compras aos Laboratórios</h1>
      <div style="text-align: center">
        <button class="btn btn-primary" v-b-modal.modal_add_product>
          Adicionar Produto
        </button>
      </div>
      <span v-if="brandsPurchase.length == 0"> <h1>Não há Pedidos</h1></span>
      <div v-for="brand of brandsPurchase" :key="brand.brand">
        <div class="card">
          <h5 class="card-header">
            <b>{{ brand.lab }}</b>
          </h5>
          <div class="card-body">
            <table class="table table-sm table-bordered">
              <thead>
                <tr>
                  <th scope="col" style="width: 7%">Data</th>
                  <th scope="col" style="width: 11%">CNP</th>
                  <th scope="col">Produto</th>
                  <th scope="col" style="width: 7%">Quant</th>
                  <th scope="col" style="width: 5%">Alvim</th>
                  <th scope="col" style="width: 5%">Martins</th>
                  <th scope="col" style="width: 5%">Perelhal</th>
                  <th scope="col" style="width: 5%">Gomes</th>
                  <th scope="col" style="width: 12%">Lab.</th>
                  <th scope="col" style="width: 18%">
                    Quantidades para Farmácias
                  </th>
                  <th scope="col" style="width: 5%">Cancelar</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="prod of brand.products" :key="prod.id_prod">
                  <td class="align-middle">
                    <span
                      v-if="calculateDifferenceBetweenDates(prod.date_request) >= 3"
                      style="background: red; color: white"
                    >
                      ⚠️ {{ splitDate2(prod.date_request) }}
                    </span>
                    <span v-else>
                      {{ splitDate2(prod.date_request) }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span v-for="ean of prod.prod_sku" :key="ean"
                      >{{ ean }} <br
                    /></span>
                    <br />
                  </td>
                  <td class="align-middle">{{ prod.name }}</td>
                  <td class="align-middle">
                    <span>
                      Qtd Pedida: <b>{{ prod.qty_missing }}</b></span
                    >
                    <br />
                    Em Falta:
                    <span
                      v-if="
                        Number(prod.qty_missing) -
                          Number(prod.alvim_order) -
                          Number(prod.martins_order) -
                          Number(prod.perelhal_order) -
                          Number(prod.gomes_order) -
                          Number(prod.marcas_order) >
                        0
                      "
                      style="color: red"
                    >
                      <b>
                        {{
                          Number(prod.qty_missing) -
                          Number(prod.alvim_order) -
                          Number(prod.martins_order) -
                          Number(prod.perelhal_order) -
                          Number(prod.gomes_order) -
                          Number(prod.marcas_order)
                        }}
                      </b></span
                    ><span v-else>0</span>
                  </td>
                  <td class="align-middle">
                    <input
                      class="form-control form-control-sm"
                      type="number"
                      min="0"
                      :max="prod.alvim_stock"
                      :disabled="
                        Number(prod.alvim_stock) <= 0 ||
                        prod.alvim_stock == 'Indisponível'
                      "
                      v-model="prod.alvim_order"
                    />{{ prod.alvim_stock }} - {{ prod.alvim_price }} €
                  </td>
                  <td class="align-middle">
                    <input
                      class="form-control form-control-sm"
                      type="number"
                      min="0"
                      :max="prod.martins_stock"
                      :disabled="
                        Number(prod.martins_stock) <= 0 ||
                        prod.martins_stock == 'Indisponível'
                      "
                      v-model="prod.martins_order"
                    />{{ prod.martins_stock }} - {{ prod.martins_price }} €
                  </td>
                  <td class="align-middle">
                    <input
                      class="form-control form-control-sm"
                      type="number"
                      min="0"
                      :max="prod.perelhal_stock"
                      :disabled="
                        Number(prod.perelhal_stock) <= 0 ||
                        prod.perelhal_stock == 'Indisponível'
                      "
                      v-model="prod.perelhal_order"
                    />{{ prod.perelhal_stock }} - {{ prod.perelhal_price }} €
                  </td>
                  <td class="align-middle">
                    <input
                      class="form-control form-control-sm"
                      type="number"
                      min="0"
                      :max="prod.gomes_stock"
                      :disabled="
                        Number(prod.gomes_stock) <= 0 ||
                        prod.gomes_stock == 'Indisponível'
                      "
                      v-model="prod.gomes_order"
                    />{{ prod.gomes_stock }} - {{ prod.gomes_price }} €
                  </td>
                  <td class="align-middle">
                    <span class="mult"> Mult. {{ prod.multiplo }}</span>
                    <input
                      class="form-control form-control-sm"
                      type="number"
                      min="0"
                      :id="
                        prod.marcas_order % prod.multiplo == 0
                          ? 'valid-input'
                          : 'invalid-input'
                      "
                      v-model="prod.marcas_order"
                      @change="checkMulti(prod, prod.marcas_order)"
                    />
                    Farmácia de Destino:
                    <select
                      class="form-control form-select"
                      v-model="prod.farma_destination"
                    >
                      <option value="Farmacia Alvim">Farmácia Alvim</option>
                      <option value="Farmacia Martins">Farmácia Martins</option>
                      <option value="Farmacia Gomes">Farmácia Gomes</option>
                      <option value="Farmacia Perelhal">
                        Farmácia Perelhal
                      </option>
                    </select>
                  </td>
                  <td class="align-middle">
                    <div class="row">
                      <label class="col-sm-5" style="text-align: right"
                        >Para Alvim:</label
                      >
                      <input
                        onclick="this.select()"
                        class="form-control form-control-sm col-sm-6"
                        type="number"
                        v-model="prod.to_alvim"
                      />
                    </div>
                    <div class="row">
                      <label class="col-sm-5" style="text-align: right"
                        >Para Martins:</label
                      >
                      <input
                        onclick="this.select()"
                        class="form-control form-control-sm col-sm-6"
                        type="number"
                        v-model="prod.to_martins"
                      />
                    </div>
                    <div class="row">
                      <label class="col-sm-5" style="text-align: right"
                        >Para Gomes:</label
                      >
                      <input
                        onclick="this.select()"
                        class="form-control form-control-sm col-sm-6"
                        type="number"
                        v-model="prod.to_gomes"
                      />
                    </div>
                    <div class="row">
                      <label class="col-sm-5" style="text-align: right"
                        >Para Perelhal:</label
                      >
                      <input
                        onclick="this.select()"
                        class="form-control form-control-sm col-sm-6"
                        type="number"
                        v-model="prod.to_perelhal"
                      />
                    </div>
                    <div style="background: red; color: white">
                      {{ checkIfQtyOrderEnough(prod) }}
                    </div>
                  </td>
                  <td
                    class="align-middle"
                    style="text-align: center"
                    @click="cancelRequest(prod.id)"
                  >
                    ❌
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <button
                class="btn btn-primary float-right"
                @click="
                  openEmail(
                    brand.lab,
                    brand.products,
                    brand.products[0].email_laboratory,
                    brand.products[0].account_laboratory,
                    brand.products[0].id_laboratories
                  )
                "
              >
                Enviar pedido à {{ brand.lab }}
              </button>
              <button
                class="btn btn-info float-right"
                @click="buyToFarmas(brand.products)"
                :disabled="checkTotalOrdersFarmas(brand.products) == 0"
              >
                Comprar às Farmácias
              </button>
            </div>
          </div>
        </div>
      </div>
    </span>
    <span v-else>
      <h1>Lista de Compras Efetuadas</h1>
      <div class="row filter">
        <div class="col-sm-3">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Estado</span
              >
            </div>
            <select
              class="custom-select"
              id="inputGroupSelect01"
              v-model="statusSearch"
            >
              <option value="requested">Pedidas</option>
              <option value="completed">Completa</option>
              <option value="cancelled">Canceladas</option>
              <option value="all">Todas</option>
            </select>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >SKU</span
              >
            </div>
            <input
              type="text"
              class="form-control"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              v-model="skuFilter"
            />
          </div>
        </div>
        <div class="col-sm-3">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Laboratório</span
              >
            </div>
            <v-select
              :options="labs"
              v-model="labFilter"
              label="name"
              style="width: 70%"
            ></v-select>
          </div>
        </div>
        <div class="col">
          <button class="btn btn-primary" @click="filterPurchases">
            🔎 Pesquisar
          </button>
        </div>
      </div>
      <b-table
        striped
        hover
        :items="danielaPurchase"
        :fields="fields"
        thead-class="text-white"
        small
      >
        <template #cell(sku)="data">
          <span v-for="prod of data.item.sku" :key="prod">
            <p>{{ prod }}</p>
          </span>
        </template>
        <template #cell(status)="data">
          <span v-if="data.item.status == 'requested'" class="requested">
            Pedido</span
          >
          <span v-else-if="data.item.status == 'completed'" class="completed">
            Completo</span
          >
          <span v-else-if="data.item.status == 'cancelled'" class="canceled">
            Anulado</span
          >
        </template>
        <template #cell(purchase_date)="data">
          {{ splitDate(data.item.purchase_date) }}
        </template>
        <template #cell(to_farma)="data">
          <span
            >Alvim: <b>{{ data.item.to_alvim }}</b></span
          >
          <br />
          <span
            >Martins: <b>{{ data.item.to_martins }}</b></span
          >
          <br />
          <span
            >Gomes: <b>{{ data.item.to_gomes }}</b></span
          >
          <br />
          <span
            >Perelhal: <b>{{ data.item.to_perelhal }}</b></span
          >
        </template>
        <template #cell(actions)="data">
          <button
            v-if="data.item.status == 'requested'"
            class="btn btn-primary btn-sm"
            @click="openModalEdit(data.item)"
          >
            Editar Pedido
          </button>
        </template>
        <template #cell(date_last_email)="data">
          {{ splitDate2(data.item.date_last_email) }}
        </template>
      </b-table>
    </span>
    <b-modal
      ref="modal_email"
      id="modal_email"
      :title="'Enviar email ao ' + lab"
      size="xl"
      hide-footer
    >
      <p style="font-size: 18px">
        Account: <b>{{ accountLabName }}</b>
      </p>
      <p style="font-size: 18px">
        Account email: <b>{{ emailToLaboratory }}</b>
      </p>
      <span v-for="email in emails" :key="email.farma">
        <h5 style="text-decoration: underline">{{ email.farma }}</h5>
        <div>
          <vue-editor v-model="email.email"></vue-editor>
        </div>
        <br />
      </span>
      <button class="btn btn-primary float-right" @click="sendEmail()">
        Enviar Email
      </button></b-modal
    >
    <b-modal
      ref="modal_add_product"
      id="modal_add_product"
      title="Associar Produto"
      hide-footer
    >
      <div class="form-group">
        <label for="sku">SKU</label>
        <input
          type="text"
          class="form-control"
          id="sku"
          v-model="skuSearch"
          @change="getProductInfo()"
        />
      </div>
      <span v-if="product.length > 0">
        <img
          :src="product[0].img"
          alt=""
          style="width: 300px; height: 300px; margin-left: 15%"
        />

        <h6>{{ product[0].name }}</h6>
        <p><b>CNP:</b> {{ product[0].sku }}</p>
        <p><b>EAN:</b> {{ product[0].ean }}</p>
        <span v-if="product[0].brand_id != null">
          <p><b>Marca:</b> {{ product[0].brand_name[0] }}</p></span
        >
        <span v-else
          ><p>
            <b>Marca:</b> <b style="color: red">Sem Marca associada</b>
          </p></span
        >
        <span v-if="product[0].laboratories_id != null">
          <p><b>Laboratório:</b> {{ product[0].laboratories_name }}</p>
        </span>
        <span v-else
          ><p>
            <b>Laboratório:</b>
            <b style="color: red">Sem Laboratório associado</b>
          </p></span
        >
      </span>
      <div>
        <button
          class="btn btn-primary"
          @click="addProduct()"
          :disabled="product.length == 0 || product[0].laboratories_id == null"
        >
          Adicionar
        </button>
      </div>
    </b-modal>
    <b-modal
      ref="modal_edit_request"
      id="modal_edit_request"
      title="Associar Produto"
      hide-footer
    >
      <form v-on:submit.prevent="editRequest()">
        <div>
          <label for="sku">SKU</label>
          <input
            type="text"
            class="form-control"
            id="sku"
            v-model="skuEdit"
            disabled
          />
        </div>
        <div>
          <label for="name">Nome</label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="nameEdit"
            disabled
          />
        </div>
        <div>
          <label for="lab">Laboratório</label>
          <input
            type="text"
            class="form-control"
            id="lab"
            v-model="labEdit"
            disabled
          />
        </div>
        <div>
          <label for="qty_order">Quantidade Pedida</label>
          <input
            type="number"
            class="form-control"
            id="qty_order"
            v-model="qty_orderEdit"
            disabled
          />
        </div>
        <div>
          <label for="to_farma">Nova Quantidade</label>
          <input
            type="number"
            class="form-control"
            id="to_farma"
            v-model="newQtyOrder"
            @change="calcNewQty()"
          />
        </div>
        <div>
          <label for="to_farma">Para Alvim:</label>
          <input
            type="number"
            class="form-control"
            id="to_farma"
            v-model="to_alvimEdit"
            :disabled="newQtyOrder == 0"
            @change="calcNewQty()"
          />
        </div>
        <div>
          <label for="to_farma">Para Martins:</label>
          <input
            type="number"
            class="form-control"
            id="to_farma"
            v-model="to_martinsEdit"
            :disabled="newQtyOrder == 0"
            @change="calcNewQty()"
          />
        </div>
        <div>
          <label for="to_farma">Para Gomes:</label>
          <input
            type="number"
            class="form-control"
            id="to_farma"
            v-model="to_gomesEdit"
            :disabled="newQtyOrder == 0"
            @change="calcNewQty()"
          />
        </div>
        <div>
          <label for="to_farma">Para Perelhal:</label>
          <input
            type="number"
            class="form-control"
            id="to_farma"
            v-model="to_perelhalEdit"
            :disabled="newQtyOrder == 0"
            @change="calcNewQty()"
          />
        </div>
        <div>
          <label for="to_idivia">Para Idivia</label>
          <input
            type="number"
            class="form-control"
            :id="
              to_idiviaEdit >= idiviaRequest ? 'valid-input' : 'invalid-input'
            "
            v-model="to_idiviaEdit"
            disabled
          />
          <small id="to_idivia" class="form-text text-muted"
            >Idivia pediu <b>{{ idiviaRequest }} unidades</b></small
          >
        </div>
        <button class="btn btn-primary" type="submit">Editar</button>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import { VueEditor } from "vue2-editor";
import moment from "moment";
export default {
  name: "BrandsPurchase",
  components: {
    // VueEditor,
  },
  data() {
    return {
      brandsPurchase: [],
      email: "",
      skuSearch: "",
      labFilter: "",
      skuFilter: "",
      product: [],
      productsToLab: [],
      lab: "",
      danielaPurchase: [],
      buyBrand: true,
      emails: [],
      skyEdit: "",
      nameEdit: "",
      labEdit: "",
      qty_orderEdit: "",
      to_farmaEdit: "",
      to_idiviaEdit: "",
      to_alvimEdit: "",
      to_martinsEdit: "",
      to_gomesEdit: "",
      to_perelhalEdit: "",
      idiviaRequest: 0,
      newQtyOrder: 0,
      idEdit: "",
      idTransactionEdit: "",
      emailToLaboratory: "",
      accountLabName: "",
      statusSearch: "requested",
      labs: [],
      fields: [
        {
          key: "sku",
          label: "CNP",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "status",
          label: "Estado",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
          sortable: true,
        },
        {
          key: "purchase_date",
          label: "Data do Pedido",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
          sortable: true,
        },
        {
          key: "name",
          label: "Nome",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "lab",
          label: "Lab",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
          sortable: true,
        },
        {
          key: "qty_order",
          label: "Quantidade Pedida",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "to_farma",
          label: "Para Farmas",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
        },
        {
          key: "to_idivia",
          label: "Para Idivia",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
        },
        {
          key: "date_last_email",
          label: "Data do Último Email",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
          sortable: true,
        },
        {
          key: "actions",
          label: "Ações",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
        },
      ],
    };
  },
  methods: {
    async getProductInfoById(id) {
      try {
        await this.$store.dispatch("fetchProductID", {
          id: id,
        });
        console.log(this.getProduct_ID.data.product);
        this.product = this.getProduct_ID.data.product;
        this.skuSearch = this.product[0].sku[0];
      } catch (error) {
        console.log(error);
      }
    },
    async allBrandsPurchase() {
      try {
        await this.$store.dispatch("setAllBrandsPurchase");
        this.brandsPurchase = this.getAllBrandsPurchase;
        console.log('PURCHASES:' ,this.brandsPurchase);
      } catch (err) {
        console.log(err);
      }
    },
    splitDate(date) {
      let split = date.split("T");
      let split2 = split[1].split(".");
      return split[0] + " " + split2[0];
    },
    splitDate2(date) {
      console.log(date);
      let split = date.split("T");
      //console.log(split[0]);
      return split[0];
    },
    async openEmail(
      lab,
      products,
      email_laboratory,
      account_name,
      id_laboratories
    ) {
      this.productsToLab = products;
      this.lab = lab;
      this.emails = [];
      this.emailToLaboratory = email_laboratory;
      this.accountLabName = account_name;
      console.log(products);
      const emailByFarma = {};

      products.forEach((element) => {
        if (element.marcas_order > 0) {
          console.log("ELEMENTO: ", element);
          const farma = element.farma_destination;
          const sku = element.sku;
          const name = element.name;
          const quantity = element.marcas_order;

          // Create or append to the email content for the specific farma
          if (!emailByFarma[farma]) {
            emailByFarma[farma] = `<p>Olá,</p>
        <p>Espero que este e-mail o encontre bem. Gostaria de efetuar a seguinte encomenda dos produtos listados abaixo:</p>
        <li>${sku} - ${name} - ${quantity} unidades</li>`;
          } else {
            emailByFarma[
              farma
            ] += `<li>${sku} - ${name} - ${quantity} unidades</li>`;
          }
        }
      });

      // Generate and save the emails for each farma
      for (const farma in emailByFarma) {
        console.log("FARMA: ", emailByFarma[farma]);
        const emailContent = emailByFarma[farma];
        const email = `${emailContent}
        <p>Agradeço que confirme a colocação desta encomenda respondendo a este e-mail, com a Dra. Daniela em cópia. Caso algum dos produtos desta encomenda não esteja disponível, agradeceria que também fosse indicado.</p>
        <p>Estou à disposição para qualquer esclarecimento adicional. Agradeço desde já a atenção dispensada.</p>
        <p>Atentamente,</p> <p>${farma}</p>`;
        // Save the email to the external array
        this.emails.push({
          farma: farma,
          email: email,
          email_laboratory: email_laboratory,
          id_laboratories: id_laboratories,
        });
        console.log(`Email for ${farma}:`, email);
      }
      this.$refs["modal_email"].show();
      console.log(this.emails);
    },
    async buyToFarmas(products) {
      this.$swal({
        title: "Fazer pedido às Farmácias?",
        text: "Após aceitar serão feitos os pedidos às farmácias!",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: `Não`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let farmas = [];
          let infoPurchase = [];
          await products.forEach((element) => {
            farmas = [];
            if (element.alvim_order > 0) {
              farmas.push({
                id_prod: element.id_prod,
                qty: element.alvim_order,
                sku: element.sku,
                price: element.alvim_price,
                name: element.name,
                ean: element.ean[0],
                supplier: "Farmacia Alvim",
                id_supp: 119
              });
            }
            if (element.martins_order > 0) {
              farmas.push({
                id_prod: element.id_prod,
                qty: element.martins_order,
                sku: element.sku,
                price: element.martins_price,
                name: element.name,
                ean: element.ean[0],
                supplier: "Farmacia Martins",
                id_supp: 174
              });
            }
            if (element.perelhal_order > 0) {
              farmas.push({
                id_prod: element.id_prod,
                qty: element.perelhal_order,
                sku: element.sku,
                price: element.perelhal_price,
                name: element.name,
                ean: element.ean[0],
                supplier: "Farmacia Perelhal",
                id_supp: 173
              });
            }
            if (element.gomes_order > 0) {
              farmas.push({
                id_prod: element.id_prod,
                qty: element.gomes_order,
                sku: element.sku,
                price: element.gomes_price,
                name: element.name,
                ean: element.ean[0],
                supplier: "Farmacia Gomes",
                id_supp: 172
              });
            }
            let newTotalOrder =
              Number(element.alvim_order) +
              Number(element.martins_order) +
              Number(element.perelhal_order) +
              Number(element.gomes_order);
            let qty_missing = Number(element.qty_missing) - newTotalOrder;
            // if (newTotalOrder < 0) newTotalOrder = 0;

            infoPurchase.push({
              id_prod: element.id,
              qty_missing: qty_missing,
              qty_remove: newTotalOrder,
              farmas: farmas,
            });
          });
          try {
            await this.$store.dispatch(
              "createNewTransactionsFromBrandsPurchase",
              {
                products: infoPurchase,
              }
            );
            await products.forEach((element) => {
              let newTotalOrder =
                Number(element.alvim_order) +
                Number(element.martins_order) +
                Number(element.perelhal_order) +
                Number(element.gomes_order);
              element.qty_missing = Number(element.qty_missing) - newTotalOrder;
              element.alvim_order = 0;
              element.martins_order = 0;
              element.perelhal_order = 0;
              element.gomes_order = 0;
            });
            this.$swal({
              toast: true,
              position: "top-end",
              title: "Sucesso!",
              text: "Pedido feito às farmácias com sucesso!",
              icon: "success",
              timer: 1500,
            });
          } catch (err) {
            alert(err);
          }
          console.log("FARMAS: ", farmas);
          console.log("INFO: ", infoPurchase);
        }
      });
    },
    async sendEmail() {
      let products = this.productsToLab;
      let marcas = [];
      let infoPurchase = [];
      console.log(products);
      await products.forEach((element) => {
        marcas = [];
        if (element.marcas_order > 0) {
          element.to_alvim = element.to_alvim == "" ? 0 : element.to_alvim;
          element.to_martins =
            element.to_martins == "" ? 0 : element.to_martins;
          element.to_gomes = element.to_gomes == "" ? 0 : element.to_gomes;
          element.to_perelhal =
            element.to_perelhal == "" ? 0 : element.to_perelhal;
            // TODO adicionar nome do produto e ean
          marcas.push({
            id_prod: element.id_prod,
            sku: element.sku,
            qty: element.marcas_order,
            supplier: element.supplier,
            price: element.p_custo,
            to_alvim: element.to_alvim,
            to_martins: element.to_martins,
            to_gomes: element.to_gomes,
            to_perelhal: element.to_perelhal,
            farma_destination: element.farma_destination,
            id_laboratories: element.id_laboratories,
            name: element.name,
            ean: element.ean,
          });
        }
        let qty_missing =
          Number(element.qty_missing) - Number(element.marcas_order);
        infoPurchase.push({
          id_prod: element.id,
          qty_missing: qty_missing,
          qty_remove: element.marcas_order,
          marca: marcas,
        });
      });
      try {
        await this.$store.dispatch("sendEmailToLab", {
          email: this.emails,
          lab: this.lab,
          products: infoPurchase,
        });
        // await products.forEach((element) => {
        //   element.qty_missing =
        //     Number(element.qty_missing) - Number(element.marcas_order);
        //   element.marcas_order = 0;
        // });
        this.$swal({
          toast: true,
          position: "top-end",
          title: "Sucesso!",
          text: "Email enviado com sucesso!",
          icon: "success",
          timer: 1500,
        });
        this.$refs["modal_email"].hide();
        await this.allBrandsPurchase();
        await this.filterPurchases();
      } catch (err) {
        alert(err);
      }
      console.log(infoPurchase);
    },
    async cancelRequest(id) {
      console.log(id);
      this.$swal({
        title: "Cancelar Pedido?",
        text: "Após aceitar o pedido será cancelado!",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: `Não`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch("cancelBrandsPurchase", {
              id: id,
            });
            this.$swal({
              toast: true,
              position: "top-end",
              title: "Sucesso!",
              text: "Pedido cancelado com sucesso!",
              icon: "success",
              timer: 1500,
            });
            await this.filterPurchases();
          } catch (err) {
            alert(err);
          }
        }
      });
    },
    checkTotalOrdersFarmas(products) {
      let total = products.reduce((acc, curr) => {
        return (
          acc +
          Number(curr.alvim_order) +
          Number(curr.martins_order) +
          Number(curr.perelhal_order) +
          Number(curr.gomes_order)
        );
      }, 0);
      console.log(total);
      return total;
    },
    async getProductInfo() {
      try {
        await this.$store.dispatch("getProductToAddBrandsPurchase", {
          sku: this.skuSearch,
        });
        console.log(this.getProduct);
        this.product = this.getProduct;
      } catch (error) {
        console.log(error);
      }
    },
    async addProduct() {
      console.log("ADICIONAR PRODUTO")
      let index = this.brandsPurchase.findIndex((element) => {
        return element.lab == this.product[0].laboratories_name;
      });
      let lab = this.labs.filter((element) => element.name == this.product[0].laboratories_name)
      let newProduct = {
        id: this.product[0].id_backoffice,
        sku: this.product[0].sku[0],
        supplier: "extraMarcas",
        qty_missing: 0,
        qty_order: 0,
        price: 0,
        qty_confirmed: 0,
        qty_received: 0,
        status: "requet",
        date_request: await this.getDate(),
        discount: 0,
        invoice_id: null,
        id_prod: this.product[0].id_backoffice,
        brand_id: this.product[0].brand_id,
        name: this.product[0].name,
        ean: this.product[0].ean,
        prod_sku: this.product[0].sku,
        alvim_stock: this.product[0].alvim_stock,
        alvim_price: this.product[0].alvim_price,
        martins_stock: this.product[0].martins_stock,
        martins_price: this.product[0].martins_price,
        perelhal_stock: this.product[0].perelhal_stock,
        perelhal_price: this.product[0].perelhal_price,
        gomes_stock: this.product[0].gomes_stock,
        gomes_price: this.product[0].gomes_price,
        id_laboratories: this.product[0].laboratories_id,
        laboratories_name: this.product[0].laboratories_name,
        alvim_order: 0,
        martins_order: 0,
        perelhal_order: 0,
        gomes_order: 0,
        marcas_order: 0,
        farma_destination: "Farmacia Alvim",
        multiplo: 3,
        to_alvim: 0,
        to_martins: 0,
        to_gomes: 0,
        to_perelhal: 0,
        account_laboratory: lab[0].client_name,
        email_laboratory: lab[0].email,
      };
      console.log(newProduct);
      if (index == -1) {
        this.brandsPurchase.push({
          lab: this.product[0].laboratories_name,
          products: [newProduct],
        });
      } else {
        this.brandsPurchase[index].products.push(newProduct);
      }
      this.$refs["modal_add_product"].hide();
    },
    async getDate() {
      let newDate = new Date();
      let date = `${newDate.getFullYear()}-${
        ('0' + (newDate.getMonth() + 1)).slice(-2)
      }-${('0' + newDate.getDate()).slice(-2)}T${newDate.getHours()}:${newDate.getMinutes()}:${newDate.getSeconds()}`;
      console.log(date);
      return date;
    },
    async allDanielaPurchase() {
      try {
        await this.$store.dispatch("danielaPurchase");
        this.danielaPurchase = this.getAllDanielaPurchase;
        console.log(this.danielaPurchase);
      } catch (err) {
        console.log(err);
      }
    },
    changeOptions(value) {
      if (value == false) this.filterPurchases();
      this.buyBrand = value;
    },
    checkMulti(prod, quantity) {
      if (quantity == 0) return true;
      let first = Math.ceil(quantity / prod.multiplo);
      let second = prod.multiplo * first;
      if (second == quantity) return true;
      else {
        this.$swal({
          icon: "warning",
          title: `A quantidade tem de ser múltiplo de ${prod.multiplo}!`,
          text: `Deseja alterar a quantidade para ${second}?`,
          confirmButtonText: "Sim, alterar quantidade",
          cancelButtonText: "Cancelar",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            prod.marcas_order = second;
            this.$swal({
              title: "Alterado!",
              text: "A quantidade foi alterada.",
              icon: "success",
            });
          }
        });
        return false;
      }
    },
    calculateDifferenceBetweenDates(date) {
      let dateNow = new Date();
      let year = dateNow.getFullYear();
      let month = ("0" + (dateNow.getMonth() + 1)).slice(-2);
      let day = ("0" + dateNow.getDate()).slice(-2);
      let finalDateNow = `${year}-${month}-${day}`;
      let orderDate = this.splitDate2(date);
      /* USAR BIBLIOTECA diff PARA COMPARAR AS DATAS */
      let diff = moment(finalDateNow).diff(orderDate, "days");
      return Number(diff);
    },
    checkIfQtyOrderEnough(prod) {
      prod.to_alvim = prod.to_alvim == "" ? 0 : prod.to_alvim;
      prod.to_martins = prod.to_martins == "" ? 0 : prod.to_martins;
      prod.to_gomes = prod.to_gomes == "" ? 0 : prod.to_gomes;
      prod.to_perelhal = prod.to_perelhal == "" ? 0 : prod.to_perelhal;
      prod.alvim_order = prod.alvim_order == "" ? 0 : prod.alvim_order;
      prod.martins_order = prod.martins_order == "" ? 0 : prod.martins_order;
      prod.gomes_order = prod.gomes_order == "" ? 0 : prod.gomes_order;
      prod.perelhal_order = prod.perelhal_order == "" ? 0 : prod.perelhal_order;
      if (
        prod.to_alvim > 0 ||
        prod.to_martins > 0 ||
        prod.to_gomes > 0 ||
        prod.to_perelhal > 0
      ) {
        let totalFarmas =
          Number(prod.to_alvim) +
          Number(prod.to_martins) +
          Number(prod.to_gomes) +
          Number(prod.to_perelhal);
        let totalNecessary =
          Number(totalFarmas) +
          Number(prod.qty_missing) -
          Number(prod.alvim_order) -
          Number(prod.martins_order) -
          Number(prod.perelhal_order) -
          Number(prod.gomes_order);
        console.log(totalNecessary);
        console.log(prod.marcas_order);
        if (totalNecessary > prod.marcas_order)
          return `⚠️ Precisa de pedir mais ${
            totalNecessary - prod.marcas_order
          } unidades ao Laboratório!`;
      } else return "";
    },
    async openModalEdit(data) {
      this.skuEdit = data.sku[0];
      this.nameEdit = data.name;
      this.labEdit = data.lab;
      this.qty_orderEdit = data.qty_order;
      this.to_farmaEdit = data.to_farma;
      this.to_idiviaEdit = data.to_idivia;
      this.to_alvimEdit = data.to_alvim;
      this.to_martinsEdit = data.to_martins;
      this.to_gomesEdit = data.to_gomes;
      this.to_perelhalEdit = data.to_perelhal;
      this.idiviaRequest = data.idivia_request;
      this.idEdit = data.id;
      this.idTransactionEdit = data.id_trans;
      this.$refs["modal_edit_request"].show();
    },
    async calcNewQty() {
      if (this.newQtyOrder == 0) {
        this.to_idiviaEdit =
          Number(this.qty_orderEdit) -
          Number(this.to_alvimEdit) -
          Number(this.to_martinsEdit) -
          Number(this.to_gomesEdit) -
          Number(this.to_perelhalEdit);
      } else {
        let newIdivia =
          Number(this.newQtyOrder) -
          Number(this.to_alvimEdit) -
          Number(this.to_martinsEdit) -
          Number(this.to_gomesEdit) -
          Number(this.to_perelhalEdit);
        this.to_idiviaEdit = newIdivia;
      }
    },
    async editRequest() {
      this.to_alvimEdit = this.to_alvimEdit == "" ? 0 : this.to_alvimEdit;
      this.to_martinsEdit = this.to_martinsEdit == "" ? 0 : this.to_martinsEdit;
      this.to_gomesEdit = this.to_gomesEdit == "" ? 0 : this.to_gomesEdit;
      this.to_perelhalEdit =
        this.to_perelhalEdit == "" ? 0 : this.to_perelhalEdit;
      this.to_idiviaEdit = this.to_idiviaEdit == "" ? 0 : this.to_idiviaEdit;
      this.newQtyOrder = this.newQtyOrder == "" ? 0 : this.newQtyOrder;
      if (this.newQtyOrder == 0) {
        this.$swal({
          icon: "question",
          title: `Este pedido ao laboratório será cancelado!`,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, cancelar!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$store.dispatch("updateLabPurchase", {
                id: this.idEdit,
                newQty: this.newQtyOrder,
                idTransaction: this.idTransactionEdit,
              });
              this.$swal({
                toast: true,
                position: "top-end",
                title: "Sucesso!",
                text: "Pedido cancelado com sucesso!",
                icon: "success",
                timer: 1500,
              });
              await this.filterPurchases();
              this.$refs["modal_edit_request"].hide();
            } catch (err) {
              alert(err);
            }
          }
        });
      } else {
        this.$swal({
          icon: "question",
          title: `Este pedido ao laboratório será editado!`,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, editar!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$store.dispatch("updateLabPurchase", {
                sku: this.skuEdit,
                newQty: this.newQtyOrder,
                to_alvim: this.to_alvimEdit,
                to_martins: this.to_martinsEdit,
                to_gomes: this.to_gomesEdit,
                to_perelhal: this.to_perelhalEdit,
                to_idivia: this.to_idiviaEdit,
                id: this.idEdit,
                idTransaction: this.idTransactionEdit,
              });
              this.$swal({
                toast: true,
                position: "top-end",
                title: "Sucesso!",
                text: "Pedido editado com sucesso!",
                icon: "success",
                timer: 1500,
              });
              await this.filterPurchases();
              this.$refs["modal_edit_request"].hide();
            } catch (err) {
              alert(err);
            }
          }
        });
      }
    },
    async getAllLabs() {
      try {
        await this.$store.dispatch("allLaboratories");
        this.labs = this.getAllLaboratories;
        console.log(this.labs);
      } catch (err) {
        console.log(err);
      }
    },
    async filterPurchases() {
      console.log(this.labFilter);
      let labName;
      if (this.labFilter == null) labName = "";
      else labName = this.labFilter.name;
      try {
        await this.$store.dispatch("filterLabsPurchases", {
          lab: labName,
          status: this.statusSearch,
          sku: this.skuFilter,
        });
        this.danielaPurchase = this.getAllDanielaPurchase;
        console.log(this.danielaPurchase);
      } catch (err) {
        console.log(err);
      }
    },
  },
  watch: {
    brandsPurchase: {
      deep: true,
      handler(newValue) {
        this.checkIfQtyOrderEnough(newValue);
      },
    },
  },
  created() {
    this.allBrandsPurchase();
    this.filterPurchases();
    this.getAllLabs();
  },
  computed: {
    ...mapGetters(["getAllBrandsPurchase"]),
    ...mapGetters(["getProduct"]),
    ...mapGetters(["getAllDanielaPurchase"]),
    ...mapGetters(["getAllLaboratories"]),
  },
};
</script>

<style scoped>
.card {
  margin: 15px 15px;
}
h1 {
  text-align: center;
}
.btn {
  margin: 0px 5px;
}

.options {
  text-align: center;
  margin: 15px 0px 15px 0px;
}
.table {
  text-align: center;
}

#invalid-input {
  border-color: red;
  border-width: 2px;
}
.mult {
  font-size: 12px;
  color: green;
}

.filter {
  padding: 30px;
}

.canceled {
  background-color: #b5182a;
  padding: 6px;
  border-radius: 8px;
  color: white;
  width: 100%;
}

.requested {
  background-color: #f0ad4e;
  padding: 6px;
  border-radius: 8px;
  color: white;
  width: 100%;
}

.completed {
  background-color: #5cb85c;
  padding: 6px;
  border-radius: 8px;
  color: white;
  width: 100%;
}
</style>
